// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateReview } from "redux/actions/review";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const ProductEdit = (props) => {
  // params
  const {
    id,
    title,
    score,
    name,
    text,
    is_operator_checked,
    is_checked,
    created_at,
    product_group,
  } = props.review;

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.review.isProcessing);
  const isUpdateComplete = useSelector(
    (state) => state.review.isUpdateComplete
  );
  const dispatch = useDispatch();

  const FIELDS = {
    title: "title",
    score: "score",
    name: "name",
    text: "text",
    isOperatorChecked: "is_operator_checked",
    isChecked: "isChecked",
    productGroup: "productGroup",
  };

  let elements = {};

  const changeReview = () => {
    const data = {
      review_id: id,
      title: elements[FIELDS.title].value,
      score: parseInt(elements[FIELDS.score].value, 10),
      name: elements[FIELDS.name].value,
      text: elements[FIELDS.text].value,
      is_operator_checked: elements[FIELDS.isOperatorChecked].value,
      is_checked: elements[FIELDS.isChecked].value,
      product_group: elements[FIELDS.productGroup].value,
    };

    if (token) {
      dispatch(updateReview(token, data));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeReview();
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      props.close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="review_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="review_item">
          <tbody>
            <tr>
              <th>タイトル</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.title}
                  defaultValue={title}
                />
              </td>
            </tr>
            <tr>
              <th>レビュー者名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                  defaultValue={name}
                />
              </td>
            </tr>
            <tr>
              <th>スコア</th>
              <td>
                <input
                  className="design_input"
                  type="number"
                  name={FIELDS.score}
                  defaultValue={score}
                />
              </td>
            </tr>
            <tr>
              <th>内容</th>
              <td>
                <textarea
                  className="design_input"
                  name={FIELDS.text}
                  style={{ resize: "none", height: "30vh" }}
                  defaultValue={text}
                />
              </td>
            </tr>
            <tr>
              <th>レビュー日時</th>
              <td>{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
            <tr>
              <th>担当者確認</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.isOperatorChecked}
                  defaultValue={is_operator_checked}
                >
                  <option value={true}>実施</option>
                  <option value={false}>未実施</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>社員確認</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.isChecked}
                  defaultValue={is_checked}
                >
                  <option value={true}>実施</option>
                  <option value={false}>未実施</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>商品グループ</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.productGroup}
                  defaultValue={product_group}
                >
                  <option value="mattress_original">マットレス</option>
                  <option value="box_sheets">ボックスシーツ</option>
                  <option value="pillow">まくら</option>
                  <option value="pillow_cover">まくらカバー</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default ProductEdit;
