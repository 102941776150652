// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import CustomerEdit from "./CustomerEdit";
import ProductEdit from "./ProductEdit";
import CouponEdit from "./CouponEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import {
  getOrderInfo,
  cancelOrder,
  refundOrder,
  changeStatusOrder,
  sendOrderMail,
  refundProduct,
  cancelProduct,
  clearError,
  clearStatus,
} from "redux/actions/order";

// utils
import { dateToString, parseDeliveryTime } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";
import {
  ORDER_STATUS,
  ORDER_ITEM_STATUS,
  convertOrderStatus,
  convertOrderItemStatus,
} from "constants/status";

// styles
import "./style.scss";

const OrderDetail = () => {
  const { orderId } = useParams();
  const applicationId = applicationList.order.id;

  const ACTIONS_BUTTONS = {
    contentsEdit: "contents_edit", // 内容を編集
    couponEdit: "coupon_edit", // クーポンを編集
    orderCancel: "order_cancel", // 注文をキャンセル
    returning: "returning", // 返品対応中へ変更
    returnCancel: "return_cancel", // 返品対応中解除
    refund: "refund", // 返金
    transferRefunded: "transfer_refunded", // 振込よる返金済み
    redeliverEmail: "redeliver_email", // 注文完了メール再配送
  };

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const order = useSelector((state) => state.order.orderInfo);
  const isIncludeUnconfirmedCancel = useSelector(
    (state) => state.order.isIncludeUnconfirmedCancel
  );

  const isCanceled = useSelector((state) => state.order.isCanceledOrder);
  const isCanceledProduct = useSelector(
    (state) => state.order.isCanceledProduct
  );
  const isChangeStatus = useSelector((state) => state.order.isChangeStatus);
  const isRefunded = useSelector((state) => state.order.isRefunded);
  const isSentMail = useSelector((state) => state.order.isSentMail);
  const error = useSelector((state) => state.order.error);
  const isProcessing = useSelector((state) => state.order.isProcessing);

  const [isCustomerEdit, setCustomerEdit] = useState(false);
  const [productEdit, setProductEdit] = useState(null);
  const [refundIdList, setRefundIdList] = useState([]);
  const [cancelItemList, setCancelItemList] = useState([]);
  const [isCouponEdit, setCouponEdit] = useState(false);
  const [showButtons, setShowButtons] = useState([]);
  const [isShowPartialCancelButton, setIsShowPartialCancelButton] =
    useState(false);
  const [isWriteAllowed, setWriteAllowed] = useState(false);
  const [isSelectedShippedItem, setIsSelectedShippedItem] = useState(false);
  const [isOrderInShippedItem, setIsOrderInShippedItem] = useState(false);

  const dispatch = useDispatch();

  const checkListShippedItem = (itemList) => {
    return itemList.some(
      (item) => item.delivery_status === ORDER_ITEM_STATUS.shipped
    );
  };

  const handleCancel = () => {
    let message = "本当にキャンセルしますか？";

    if (checkListShippedItem(order.product_list)) {
      message =
        "発送済み商品のキャンセルが含まれています。\n商品の返送手続きは完了していますか？";
    }

    if (window.confirm(message)) {
      dispatch(cancelOrder(token, order.order_number));
    }
  };

  const cancelByProduct = () => {
    const items = cancelItemList.map((item) => item.product_name).join("\n");
    let message = `${items}\n\n上記の商品をキャンセルしますか？`;

    // 発送済み商品がキャンセルされた場合
    if (checkListShippedItem(cancelItemList)) {
      message =
        "発送済み商品のキャンセルが含まれています。\n商品の返送手続きは完了していますか？";
    }

    const list = cancelItemList.map((item) => item.id);

    if (window.confirm(message)) {
      const data = {
        order_number: order.order_number,
        product_pk_list: list,
      };
      dispatch(cancelProduct(token, data));
      setCancelItemList([]);
    }
  };

  const handleChangeStatus = (status) => {
    let message = "";

    switch (status) {
      case "returning":
        message = "注文ステータスを返品対応中に変更しますか？";
        break;
      case "done":
        message = "返品対応を中止し、注文ステータスを配送完了に戻しますか？";
        break;
      default:
    }

    if (!message) return;

    if (window.confirm(message)) {
      dispatch(changeStatusOrder(token, order.order_number, status));
    }
  };

  const handleRefund = (isTransferred) => {
    const message = isTransferred
      ? "振り込みによる返金が完了していますか？"
      : "返金処理を開始してもよろしいですか？";

    if (window.confirm(message)) {
      dispatch(refundOrder(token, order.order_number, isTransferred));
    }
  };

  const handleSendMail = () => {
    if (window.confirm("本当に注文完了メールを再送しますか？")) {
      dispatch(sendOrderMail(token, order.order_number));
    }
  };

  const appendRefundList = (productID) => {
    setRefundIdList([...refundIdList, productID]);
  };

  const removeRefundList = (productID) => {
    setRefundIdList(refundIdList.filter((id) => id !== productID));
  };

  const appendCancelList = (product) => {
    setCancelItemList([...cancelItemList, product]);
  };

  const removeCancelList = (productID) => {
    setCancelItemList(cancelItemList.filter((item) => item.id !== productID));
  };

  const refundByProduct = (isTransferred) => {
    const message = isTransferred
      ? "チェックを入れた商品は振込にて返金が完了していますか？"
      : "チェックを入れた商品の返金を行いますか？";

    if (window.confirm(message)) {
      const data = {
        order_number: order.order_number,
        product_pk_list: refundIdList,
        is_transferred: isTransferred,
      };
      dispatch(refundProduct(token, data));
    }
  };

  const fetchOrder = () => {
    if (token) {
      dispatch(getOrderInfo(token, orderId));
    }
  };

  const convertAmazonPayAuthStatus = (status) => {
    switch (status) {
      case "pending":
        return "審査中";
      case "success":
        return "承認";
      case "failed":
        return "不承認";
      default:
        return "";
    }
  };

  useEffect(() => {
    setIsSelectedShippedItem(checkListShippedItem(cancelItemList));
  }, [cancelItemList]);

  useEffect(() => {
    setIsOrderInShippedItem(checkListShippedItem(order.product_list));
  }, [order.product_list]);

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    if (isRefunded) {
      window.alert("返金リクエストが完了しました");
      dispatch(getOrderInfo(token, orderId));
      dispatch(clearStatus());
    }
  }, [isRefunded]);

  useEffect(() => {
    if (isCanceled) {
      let message = isIncludeUnconfirmedCancel
        ? "注文をキャンセルしました。\n（注意）発送手続きが進んでいるためキャンセルが失敗する恐れがあります。お客様に受取拒否の依頼をお願いします。"
        : "注文をキャンセルしました";
      window.alert(message);
      dispatch(getOrderInfo(token, orderId));
      dispatch(clearStatus());
    }
  }, [isCanceled]);

  useEffect(() => {
    if (isCanceledProduct) {
      let message = isIncludeUnconfirmedCancel
        ? "一部商品をキャンセルしました\n（注意）発送手続きが進んでいるためキャンセルが失敗する恐れがあります。お客様に受取拒否の依頼をお願いします。"
        : "一部商品をキャンセルしました";
      window.alert(message);
      dispatch(getOrderInfo(token, orderId));
      dispatch(clearStatus());
    }
  }, [isCanceledProduct]);

  useEffect(() => {
    if (isChangeStatus) {
      window.alert("ステータスを変更しました");
      dispatch(getOrderInfo(token, orderId));
      dispatch(clearStatus());
    }
  }, [isChangeStatus]);

  useEffect(() => {
    if (isSentMail) {
      window.alert("メールを送信しました");
      dispatch(clearStatus());
    }
  }, [isSentMail]);

  useEffect(() => {
    switch (order.order_status) {
      case ORDER_STATUS.unshipped: // 未発送
        setShowButtons([
          ACTIONS_BUTTONS.contentsEdit,
          ACTIONS_BUTTONS.couponEdit,
          ACTIONS_BUTTONS.orderCancel,
          ACTIONS_BUTTONS.redeliverEmail,
        ]);
        setIsShowPartialCancelButton(true);
        break;
      case ORDER_STATUS.partiallyShipped: // 一部発送済み
      case ORDER_STATUS.partiallyReturned: // 一部返品済み
      case ORDER_STATUS.shipped: // 発送済み
        setShowButtons([
          ACTIONS_BUTTONS.contentsEdit,
          ACTIONS_BUTTONS.couponEdit,
          ACTIONS_BUTTONS.returning,
          ACTIONS_BUTTONS.orderCancel,
          ACTIONS_BUTTONS.redeliverEmail,
        ]);
        setIsShowPartialCancelButton(true);
        break;
      case ORDER_STATUS.returning: // 返品手続中
        setShowButtons([
          ACTIONS_BUTTONS.refund,
          ACTIONS_BUTTONS.returnCancel,
          ACTIONS_BUTTONS.transferRefunded,
        ]);
        break;
      default:
        setShowButtons([]);
        setIsShowPartialCancelButton(false);
    }
  }, [order.order_status]);

  useEffect(() => {
    setWriteAllowed(checkPermission(myAccount, applicationId, "write"));
  }, [myAccount]);

  return (
    <>
      <div className="order_detail detail">
        <div className="list_back_container">
          <Link to="/order">一覧に戻る</Link>
        </div>
        {order.similar_return_order_list.length > 0 && (
          <div className="return_order_list_container">
            <p>下記カート番号の注文と類似している注文情報です</p>
            <div className="cart_id_container">
              {order.similar_return_order_list.map((cart_id, index) => (
                <div key={index}>{cart_id}</div>
              ))}
            </div>
          </div>
        )}
        <div className="order_flex">
          <div className="order_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>カート番号</th>
                  <td>{order.cart_id}</td>
                </tr>
                <tr>
                  <th>注文番号</th>
                  <td>{order.order_number}</td>
                </tr>
                <tr>
                  <th>注文日時</th>
                  <td>
                    {order.order_at &&
                      dateToString(order.order_at, "yyyy/MM/dd HH:mm")}
                  </td>
                </tr>
                <tr>
                  <th>流入経路</th>
                  <td>{order.route}</td>
                </tr>
                {order.method === "amazon_pay" &&
                  order.dynamic_authorization_status && (
                    <tr>
                      <th>
                        Amazon Pay
                        <br />
                        審査ステータス
                      </th>
                      <td>
                        {convertAmazonPayAuthStatus(
                          order.dynamic_authorization_status
                        )}
                      </td>
                    </tr>
                  )}
                <tr>
                  <th>注文ステータス</th>
                  <td>{convertOrderStatus(order.order_status)}</td>
                </tr>
                <tr>
                  <th>ユーザID</th>
                  <td>{order.user_id}</td>
                </tr>
                <tr>
                  <th>氏名</th>
                  <td>{order.customer_fullname}</td>
                </tr>
                <tr>
                  <th>メール</th>
                  <td>{order.customer_mail}</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>{order.customer_phone}</td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td>{order.postal_code}</td>
                </tr>
                <tr>
                  <th>都道府県</th>
                  <td>{order.province}</td>
                </tr>
                <tr>
                  <th>市区町村/町名</th>
                  <td>{order.city}</td>
                </tr>
                <tr>
                  <th>番地以降の住所</th>
                  <td>{order.address_line}</td>
                </tr>
                <tr>
                  <th>購入方法</th>
                  <td>{order.method}</td>
                </tr>
                <tr>
                  <th>小計</th>
                  <td>&yen;{order.sub_total.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>クーポンコード</th>
                  <td>{order.coupon_code ?? "なし"}</td>
                </tr>
                <tr>
                  <th>クーポン割引額</th>
                  <td>&yen;{order.discount.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>税金</th>
                  <td>&yen;{order.tax.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>送料</th>
                  <td>&yen;{order.carriage.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>合計</th>
                  <td>&yen;{order.total.toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
            {isWriteAllowed && (
              <div className="button_container">
                {showButtons.includes(ACTIONS_BUTTONS.contentsEdit) && (
                  <button
                    className="design_button"
                    onClick={() => setCustomerEdit(true)}
                    disabled={isProcessing}
                  >
                    内容を編集
                  </button>
                )}
                {showButtons.includes(ACTIONS_BUTTONS.couponEdit) && (
                  <button
                    className="design_button"
                    onClick={() => setCouponEdit(true)}
                    disabled={isProcessing}
                  >
                    クーポン編集
                  </button>
                )}
                {showButtons.includes(ACTIONS_BUTTONS.orderCancel) && (
                  <button
                    className={`design_button ${
                      isOrderInShippedItem ? "shipped_cancel_button" : ""
                    }`}
                    onClick={() => handleCancel()}
                    disabled={isProcessing}
                  >
                    {isOrderInShippedItem
                      ? "出庫後キャンセル"
                      : "注文をキャンセル"}
                  </button>
                )}
                {showButtons.includes(ACTIONS_BUTTONS.returning) && (
                  <button
                    className="design_button create_button"
                    onClick={() => handleChangeStatus("returning")}
                    disabled={isProcessing}
                  >
                    返品対応中へ変更
                  </button>
                )}
                {showButtons.includes(ACTIONS_BUTTONS.returnCancel) && (
                  <button
                    className="design_button create_button"
                    onClick={() => handleChangeStatus("done")}
                    disabled={isProcessing}
                  >
                    返品対応キャンセル
                  </button>
                )}
                {showButtons.includes(ACTIONS_BUTTONS.refund) && (
                  <button
                    className="design_button delete_button"
                    onClick={() => handleRefund(false)}
                    disabled={isProcessing}
                  >
                    返金処理リクエスト
                  </button>
                )}
                {showButtons.includes(ACTIONS_BUTTONS.refund) && (
                  <button
                    className="design_button delete_button"
                    onClick={() => handleRefund(true)}
                    disabled={isProcessing}
                  >
                    振込返金済み
                  </button>
                )}
                {showButtons.includes(ACTIONS_BUTTONS.redeliverEmail) && (
                  <button
                    className="design_button"
                    onClick={() => handleSendMail()}
                    disabled={isProcessing}
                  >
                    注文完了メール再送
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="order_product_container">
            {order.product_list.map((product, index) => (
              <ProductItem
                product={product}
                productEdit={(product) => setProductEdit(product)}
                index={index + 1}
                productCount={order.product_list.length}
                key={index}
                appendRefundCheck={() => appendRefundList(product.id)}
                removeRefundCheck={() => removeRefundList(product.id)}
                appendCancelCheck={() => appendCancelList(product)}
                removeCancelCheck={() => removeCancelList(product.id)}
                isWriteAllowed={isWriteAllowed}
                orderStatus={order.order_status}
              />
            ))}
            {isWriteAllowed &&
              order.order_status === ORDER_STATUS.returning && (
                <div className="refund_button_container">
                  <button
                    className="design_button"
                    style={{ display: "block" }}
                    onClick={() => refundByProduct(false)}
                    disabled={refundIdList.length === 0 || isProcessing}
                  >
                    選択した商品の返金処理リクエスト
                  </button>
                  <button
                    className="design_button"
                    style={{ display: "block", marginTop: "2vh" }}
                    onClick={() => refundByProduct(true)}
                    disabled={refundIdList.length === 0 || isProcessing}
                  >
                    選択した商品のステータスを返金済みに変更
                  </button>
                </div>
              )}
            {isWriteAllowed && isShowPartialCancelButton && (
              <div className="cancel_button_container">
                <button
                  className={`design_button ${
                    isSelectedShippedItem ? "shipped_cancel_button" : ""
                  }`}
                  style={{ display: "block" }}
                  onClick={() => cancelByProduct()}
                  disabled={cancelItemList.length === 0 || isProcessing}
                >
                  {isSelectedShippedItem
                    ? "選択した商品（出庫後商品を含む）をキャンセル"
                    : "選択した商品をキャンセル"}
                </button>
              </div>
            )}
            <div className="order_history_list_container">
              <div className="order_history_title">変更履歴</div>
              {order.history_list.map((history, index) => (
                <HistoryItem history={history} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isCustomerEdit && (
        <Modal close={() => setCustomerEdit(false)}>
          <CustomerEdit
            order={order}
            close={() => {
              setCustomerEdit(false);
              fetchOrder();
            }}
          />
        </Modal>
      )}
      {productEdit && (
        <Modal close={() => setProductEdit(null)}>
          <ProductEdit
            product={productEdit}
            orderNumber={order.order_number}
            close={() => {
              setProductEdit(null);
              fetchOrder();
            }}
          />
        </Modal>
      )}
      {isCouponEdit && (
        <Modal close={() => setCouponEdit(false)}>
          <CouponEdit
            orderNumber={order.order_number}
            couponCode={order.coupon_code}
            close={() => {
              setCouponEdit(false);
              fetchOrder();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default OrderDetail;

const ProductItem = (props) => {
  const {
    productEdit,
    index,
    productCount,
    appendRefundCheck,
    removeRefundCheck,
    appendCancelCheck,
    removeCancelCheck,
    isWriteAllowed,
    product,
    orderStatus,
  } = props;

  const {
    product_name,
    preferred_delivery_date,
    preferred_delivery_time,
    scheduled_delivery_date,
    delivery_status,
    shipment_id,
    tracking_code,
  } = product;

  // 注文ステータスが「返品中」で、商品ステータスが「発送済み」の場合、返金ボタンを表示
  const isShowRefund =
    orderStatus === ORDER_STATUS.returning &&
    delivery_status === ORDER_ITEM_STATUS.shipped;

  // 商品ステータスが「発送済み」または「未発送」の場合、キャンセルボタンを表示
  const isShowCancel =
    delivery_status === ORDER_ITEM_STATUS.shipped ||
    delivery_status === ORDER_ITEM_STATUS.unshipped;

  // 商品ステータスが「未発送」の場合、編集ボタンを表示
  const isShowEdit = delivery_status === ORDER_ITEM_STATUS.unshipped;

  const [isRefundCheck, setIsRefundCheck] = useState(false);
  const [isCancelCheck, setIsCancelCheck] = useState(false);

  useEffect(() => {
    if (isRefundCheck) {
      appendRefundCheck();
    } else {
      removeRefundCheck();
    }
  }, [isRefundCheck]);

  useEffect(() => {
    if (isCancelCheck) {
      appendCancelCheck();
    } else {
      removeCancelCheck();
    }
  }, [isCancelCheck]);

  return (
    <div className="product_item">
      <div className="item_count">
        {index} / {productCount}
      </div>
      <table>
        <tbody>
          <tr>
            <th>商品名</th>
            <td>{product_name}</td>
          </tr>
          <tr>
            <th>配送希望日</th>
            <td>
              {preferred_delivery_date || "希望なし"}{" "}
              {parseDeliveryTime(preferred_delivery_time)}
            </td>
          </tr>
          <tr>
            <th>配送予定日</th>
            <td>{scheduled_delivery_date || "未定"}</td>
          </tr>
          <tr>
            <th>配送状況</th>
            <td>{convertOrderItemStatus(delivery_status)}</td>
          </tr>
          <tr>
            <th>出庫ID</th>
            <td>{shipment_id || "未設定"}</td>
          </tr>
          <tr>
            <th>追跡番号</th>
            <td>{tracking_code || "未設定"}</td>
          </tr>
        </tbody>
      </table>
      {isWriteAllowed && isShowEdit && (
        <div className="product_button_container">
          <button
            className="design_button"
            onClick={() => productEdit(product)}
          >
            内容を編集
          </button>
        </div>
      )}
      {isWriteAllowed && isShowRefund && (
        <div className="product_refund_checkbox_container">
          <label>
            <input
              type="checkbox"
              checked={isRefundCheck}
              onChange={() => setIsRefundCheck(!isRefundCheck)}
            />
            <div>
              この商品の返金する場合はチェックを入れて
              <br />
              【選択した商品の返金】ボタンをクリックしてください
            </div>
          </label>
        </div>
      )}
      {isWriteAllowed && isShowCancel && (
        <div className="product_cancel_checkbox_container">
          <label>
            <input
              type="checkbox"
              checked={isCancelCheck}
              onChange={() => setIsCancelCheck(!isCancelCheck)}
            />
            <div>
              この商品のキャンセルする場合はチェックを入れて
              <br />
              【選択した商品のキャンセル】ボタンをクリックしてください
            </div>
          </label>
        </div>
      )}
    </div>
  );
};

const HistoryItem = ({ history }) => {
  const { id, change_at, change_by, change_info_list } = history;

  return (
    <div className="history_container">
      <table>
        <tbody>
          <tr>
            <th>変更日時</th>
            <td>{dateToString(change_at, "yyyy/MM/dd HH:mm:ss")}</td>
          </tr>
          <tr>
            <th>変更者</th>
            <td>{change_by}</td>
          </tr>
        </tbody>
      </table>
      <div className="change_container">
        <table>
          <tbody>
            {change_info_list.map((change_info, index) => (
              <tr key={index}>
                <th>{change_info.params}</th>
                <td>
                  {change_info.before} &gt; {change_info.after}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
