const errorMessages = [
  {
    code: 1000,
    context:
      "アクセストークンの有効期限切れ、または不正なトークンが設定されています。再度ログインしてください。",
  },
  {
    code: 1001,
    context: "IDまたはパスワードが違います。",
  },
  {
    code: 1002,
    context: "変更前と同じパスワードです。",
  },
  {
    code: 1003,
    context:
      "権限がありません。権限が必要な場合はシステム管理者までご連絡ください。",
  },
  {
    code: 2000,
    context: "IDまたはパスワードが違います。",
  },
  {
    code: 3000,
    context: "不正なソートが設定されています。",
  },
  {
    code: 4000,
    context: "注文番号が存在しません。",
  },
  {
    code: 4002,
    context: "在庫がない商品が含まれています。",
  },
  {
    code: 4003,
    context:
      "出庫状況がまだ更新されていません。時間をおいて再度実行してください。",
  },
  {
    code: 4007,
    context: "郵便番号と住所が一致しません。",
  },
  {
    code: 4009,
    context: "0000で終わる郵便番号は使用できません。",
  },
  {
    code: 4011,
    context: "配送できない地域です。",
  },
  {
    code: 4024,
    context:
      "「以降の住所」は市・区の情報を除き全角32文字（半角64文字）以内で入力してください。",
  },
  {
    code: 4025,
    context: "「氏名」は全角12文字（半角25文字）以内で入力してください。",
  },
  {
    code: 5000,
    context: "すでに発送済みです。",
  },
  {
    code: 5001,
    context: "存在しない郵便番号です。",
  },
  {
    code: 6000,
    context: "返金できませんでした。システム管理者までご連絡ください。",
  },
  {
    code: 9003,
    context: "openlogi で エラーが発生しました。",
  },
  {
    code: 15000,
    context: "すでに存在するクーポンコードです。",
  },
  {
    code: 15001,
    context: "不正なクーポンコードです。",
  },
  {
    code: 15002,
    context: "不正な割引率です。",
  },
  {
    code: 15003,
    context: "使用期限に不正な数値が入力されています。",
  },
  {
    code: 15004,
    context: "使用期限が使用開始日時より前に設定されています。",
  },
  {
    code: 15005,
    context: "使用期限に不正な数値が入力されています。",
  },
  {
    code: 15006,
    context: "使用開始日時に不正な数値が入力されています。",
  },
  {
    code: 16000,
    context: "存在しないクーポンコードです。",
  },
  {
    code: 16001,
    context: "すでに設定されているクーポンです。",
  },
  {
    code: 16002,
    context: "設定されているクーポンより割引額が小さく、反映できません。",
  },
  {
    code: 16003,
    context: "無効状態のクーポンコードです。",
  },
  {
    code: 16004,
    context: "期限切れのクーポンコードです。",
  },
  {
    code: 16005,
    context: "使用開始日時前のクーポンコードです。",
  },
  {
    code: 17000,
    context: "パラメータが不足しています。",
  },
  {
    code: 18000,
    context: "存在しないIDです。",
  },
  {
    code: 20000,
    context:
      "amazonpay の capture が失敗しました。システム管理者までご連絡ください。",
  },
  {
    code: 20001,
    context: "amazonpayの請求が完了していません。",
  },
  {
    code: 21000,
    context: "存在しないIDです。",
  },
  {
    code: 21001,
    context:
      "選択できない入庫予定日です。最新の入庫予定日以降の日付を選択してください。",
  },
  {
    code: 21002,
    context: "入庫IDは必ず入力してください。",
  },
  {
    code: 21003,
    context:
      "入庫IDが不正です。openlogiで作成済みの入庫IDであるか確認してください。または、登録済みの入庫IDです。",
  },
  {
    code: 21004,
    context:
      "既に購入者がいる入庫予定は無効にできません。それでも無効にしたい場合は、エンジニアに相談してください。",
  },
  {
    code: 22000,
    context: "不正なステータスです。",
  },
  {
    code: 23001,
    context: "不正なタグが設定されています。",
  },
  {
    code: 24000,
    context: "存在しないIDです。",
  },
  {
    code: 25000,
    context: "FAQのIDが不正です。",
  },
  {
    code: 25001,
    context: "すでに使用されているIDです。",
  },
  {
    code: 25002,
    context: "すでに使用されている優先度です。",
  },
  {
    code: 26000,
    context: "カテゴリのIDが不正です。",
  },
  {
    code: 27000,
    context: "不正な商品IDです。",
  },
  {
    code: 27001,
    context: "不正なクーポンが使用されており返金額を計算できません。",
  },
  {
    code: 27002,
    context: "返金上限を超えています。",
  },
  {
    code: 28000,
    context: "不正なセールIDです。",
  },
  {
    code: 28001,
    context: "不正なクーポンコードが設定されています。",
  },
  {
    code: 28002,
    context: "不正なセール期間が設定されています。",
  },
  {
    code: 29000,
    context: "存在しないIDです。",
  },
  {
    code: 29001,
    context: "不正な投稿URLです。",
  },
  {
    code: 29002,
    context:
      "自動取得機能にエラーが発生しました。しばらく時間をおいてください。",
  },
  {
    code: 29003,
    context: "不正なメディアURLです。",
  },
  {
    code: 29004,
    context: "不正な登録モードです。",
  },
  {
    code: 29005,
    context: "不正なメディアIDです。",
  },
  {
    code: 30000,
    context: "不正な gift id です。",
  },
  {
    code: 30001,
    context: "不正な openlogi product id です。",
  },
  {
    code: 30002,
    context: "不正な配送希望日です。",
  },
  {
    code: 30003,
    context: "沖縄県への配送日修正はできません。",
  },
  {
    code: 30004,
    context: "不正な nell product id です。",
  },
  {
    code: 30005,
    context: "不正な電話番号です。",
  },
  {
    code: 30006,
    context:
      "不正な配送希望時間帯が設定されているか\n配送希望時間帯が設定できない地域です\n（配送希望時間帯を「配送希望なし」に変更してください）。",
  },
  {
    code: 30007,
    context: "商品IDが不正です。",
  },
  {
    code: 30008,
    context: "商品リストが不正です。",
  },
  {
    code: 30009,
    context:
      "マットレス以外の商品の配送日時が統一されていません。\nまとめて配送を行うため、統一する必要があります。",
  },
  {
    code: 31000,
    context: "すでにクーポン適用処理が動作しているため、新たに実行できません。",
  },
  {
    code: 32000,
    context: "商品グループのIDが不正です。",
  },
  {
    code: 36001,
    context:
      "アカウントがロックされています。解除が必要な場合、管理者に連絡してください。",
  },
  {
    code: 36002,
    context: "アカウントIDが無効、またはアカウントが存在しません。",
  },
  {
    code: 36003,
    context: "ID、またはパスワードが間違っています。",
  },
  {
    code: 36004,
    context: "店舗IDが不正です。",
  },
  {
    code: 36005,
    context: "店舗IDが不正です。すでに存在するIDです。",
  },
  {
    code: 36006,
    context: "店舗名が不正です。",
  },
  {
    code: 36007,
    context: "セールIDが不正です。",
  },
  {
    code: 36008,
    context: "店舗リストが不正です。",
  },
  {
    code: 36009,
    context: "セール名が不正です。",
  },
  {
    code: 36010,
    context: "割引率が不正です。",
  },
  {
    code: 36011,
    context: "開始日時が不正です。",
  },
  {
    code: 36012,
    context: "終了日時が不正です。",
  },
  {
    code: 36013,
    context: "商品IDが不正です。",
  },
  {
    code: 36014,
    context:
      "ステータスが不正です。Shippingオブジェクトに指定できないステータスです。",
  },
  {
    code: 36015,
    context:
      "ステータスが不正。現在のShippingオブジェクトに指定できないステータスです。",
  },
  {
    code: 36016,
    context: "関連する注文が存在するため削除できません。",
  },
  {
    code: 36017,
    context: "オフラインセールの期間が重複しています。",
  },
  {
    code: 36018,
    context: "すでにこのセールで購入のある店舗が存在し、削除できません。",
  },
  {
    code: 36019,
    context:
      "配送準備が進んでいるため、住所変更ができません。\n出庫後、追跡番号をもとに直接配送業者へ連絡ください。\nまた、出庫IDをもとにOLカスタマーサービスへ連絡してください。",
  },
];

const defaultErrorMessage = {
  context:
    "予期せぬエラーが発生致しました。問題が解決しない場合、システム管理者までお知らせください。",
};

export const getErrorMessageByCode = (code) => {
  const errorMessage = errorMessages.find((error) => error.code === code);
  return errorMessage ? errorMessage : defaultErrorMessage + "(" + code + ")";
};
