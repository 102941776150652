import axios from "../network";
import * as types from "../types/order";

// ==================== 注文一覧取得 ====================
const getOrdersRequest = () => {
  return {
    type: types.GET_ORDERS_REQUEST,
  };
};

const getOrdersSuccess = (data) => {
  return {
    type: types.GET_ORDERS_SUCCESS,
    data,
  };
};

const getOrdersFailure = (error) => {
  return {
    type: types.GET_ORDERS_FAILURE,
    error,
  };
};

/**
 * 注文一覧を取得する関数
 * @param {string} token トークン
 * @param {Object} data
 * @param {string} data.order_number 注文番号
 * @param {string} data.customer_fullname 注文者氏名
 * @param {string} data.order_status 注文ステータス
 * @param {string} data.order_date_start 注文日start
 * @param {string} data.order_date_end 注文日end
 * @param {string} data.offset 取得位置
 * @param {string} data.limit 取得上限数
 * @param {string} data.sort_by ソートパラメータ
 * @param {string} data.sort_type 昇順降順
 *
 */
export const getOrders = (token, data) => {
  return (dispatch) => {
    dispatch(getOrdersRequest());
    return axios
      .get("/admin/order/list/", {
        headers: { token: token },
        params: data,
        data: {},
      })
      .then((res) => {
        return dispatch(getOrdersSuccess(res.data));
      })
      .catch((err) => dispatch(getOrdersFailure(err)));
  };
};

// ==================== 注文情報取得 ====================
const getOrderInfoRequest = () => {
  return {
    type: types.GET_ORDER_INFO_REQUEST,
  };
};

const getOrderInfoSuccess = (data) => {
  return {
    type: types.GET_ORDER_INFO_SUCCESS,
    data,
  };
};

const getOrderInfoFailure = (error) => {
  return {
    type: types.GET_ORDER_INFO_FAILURE,
    error,
  };
};

/**
 * 注文情報を取得する関数
 * @param {string} token トークン
 * @param {string} order_number 注文番号
 */
export const getOrderInfo = (token, orderNumber) => {
  return (dispatch) => {
    dispatch(getOrderInfoRequest());
    return axios
      .get("/admin/order/", {
        headers: { token: token },
        params: { order_number: orderNumber },
        data: {},
      })
      .then((res) => {
        return dispatch(getOrderInfoSuccess(res.data));
      })
      .catch((err) => dispatch(getOrderInfoFailure(err)));
  };
};

// ==================== 注文者情報変更 ====================
const changeOrderRequest = () => {
  return {
    type: types.CHANGE_ORDER_REQUEST,
  };
};

const changeOrderSuccess = (data) => {
  return {
    type: types.CHANGE_ORDER_SUCCESS,
    data,
  };
};

const changeOrderFailure = (error) => {
  return {
    type: types.CHANGE_ORDER_FAILURE,
    error,
  };
};

/**
 * 注文情報を更新する関数
 * @param {string} token トークン
 * @param {Object} data
 * @param {string} data.order_number 注文番号
 * @param {string} data.customer_fullname 氏名
 * @param {string} data.customer_mail メールアドレス
 * @param {string} data.customer_phone 電話番号
 * @param {string} data.postalCode 郵便番号
 * @param {string} data.province 都道府県
 * @param {string} data.city 市区町村/町名
 * @param {string} data.address_line 番地以降の住所
 * @param {Array<Object>} data.product_delivery_date_time
 * @param {string} data.product_delivery_date_time[].id カート内商品ID
 * @param {string} data.product_delivery_date_time[].delivery_date 配送予定日
 * @param {string} data.product_delivery_date_time[].delivery_time 配送予定時間
 */
export const changeOrder = (token, data) => {
  return (dispatch) => {
    dispatch(changeOrderRequest());
    return axios
      .put("/admin/order/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(changeOrderSuccess(res.data));
      })
      .catch((err) => dispatch(changeOrderFailure(err)));
  };
};

// ==================== クーポン変更 ====================
const changeCouponRequest = () => {
  return {
    type: types.CHANGE_COUPON_REQUEST,
  };
};

const changeCouponSuccess = (data) => {
  return {
    type: types.CHANGE_COUPON_SUCCESS,
    data,
  };
};

const changeCouponFailure = (error) => {
  return {
    type: types.CHANGE_COUPON_FAILURE,
    error,
  };
};

/**
 * クーポンを変更する関数
 * @param {string} token トークン
 * @param {Object} data
 * @param {string} data.order_number 注文番号
 * @param {string} data.coupon_code クーポンコード
 */
export const changeCoupon = (token, data) => {
  return (dispatch) => {
    dispatch(changeCouponRequest());
    return axios
      .put("/admin/order/coupon/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(changeCouponSuccess(res.data));
      })
      .catch((err) => dispatch(changeCouponFailure(err)));
  };
};

// ==================== キャンセル ====================
const cancelOrderRequest = () => {
  return {
    type: types.CANCEL_ORDER_REQUEST,
  };
};

const cancelOrderSuccess = (data) => {
  return {
    type: types.CANCEL_ORDER_SUCCESS,
    data,
  };
};

const cancelOrderFailure = (error) => {
  return {
    type: types.CANCEL_ORDER_FAILURE,
    error,
  };
};

/**
 * 注文をキャンセルする関数
 * @param {string} token トークン
 * @param {string} order_number 注文番号
 */
export const cancelOrder = (token, orderNumber) => {
  return (dispatch) => {
    dispatch(cancelOrderRequest());
    return axios
      .post(
        "/admin/order/cancel/",
        { order_number: orderNumber },
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        return dispatch(cancelOrderSuccess(res.data));
      })
      .catch((err) => dispatch(cancelOrderFailure(err)));
  };
};
// ==================== ステータス変更の関数 ====================
const changeStatusOrderRequest = () => {
  return {
    type: types.CHANGE_STATUS_ORDER_REQUEST,
  };
};

const changeStatusOrderSuccess = (data) => {
  return {
    type: types.CHANGE_STATUS_ORDER_SUCCESS,
    data,
  };
};

const changeStatusOrderFailure = (error) => {
  return {
    type: types.CHANGE_STATUS_ORDER_FAILURE,
    error,
  };
};

/**
 * ステータスを変更するする関数
 * @param {string} token トークン
 * @param {string} order_number 注文番号
 * @param {string} status 変更後のステータス
 */
export const changeStatusOrder = (token, orderNumber, status) => {
  return (dispatch) => {
    dispatch(changeStatusOrderRequest());
    return axios
      .put(
        "/admin/order/status/",
        {
          order_number: orderNumber,
          status: status,
        },
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        return dispatch(changeStatusOrderSuccess(res.data));
      })
      .catch((err) => dispatch(changeStatusOrderFailure(err)));
  };
};

// ==================== 返金 ====================
const refundOrderRequest = () => {
  return {
    type: types.REFUND_ORDER_REQUEST,
  };
};

const refundOrderSuccess = (data) => {
  return {
    type: types.REFUND_ORDER_SUCCESS,
    data,
  };
};

const refundOrderFailure = (error) => {
  return {
    type: types.REFUND_ORDER_FAILURE,
    error,
  };
};

/**
 * 返金する関数
 * @param {string} token トークン
 * @param {string} order_number 注文番号
 */
export const refundOrder = (token, orderNumber, isTransferred) => {
  return (dispatch) => {
    dispatch(refundOrderRequest());
    return axios
      .post(
        "/admin/order/refund/",
        { order_number: orderNumber, is_transferred: isTransferred },
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        return dispatch(refundOrderSuccess(res.data));
      })
      .catch((err) => dispatch(refundOrderFailure(err)));
  };
};

// ==================== 注文商品情報変更 ====================
const changeOrderProductRequest = () => {
  return {
    type: types.CHANGE_ORDER_PRODUCT_REQUEST,
  };
};

const changeOrderProductSuccess = (data) => {
  return {
    type: types.CHANGE_ORDER_PRODUCT_SUCCESS,
    data,
  };
};

const changeOrderProductFailure = (error) => {
  return {
    type: types.CHANGE_ORDER_PRODUCT_FAILURE,
    error,
  };
};

/**
 * 注文した商品情報を変更する関数
 * @param {string} token トークン
 * @param {Object} data
 * @param {string} data.product_id 商品ID
 * @param {string} data.delivery_date 配送予定日
 * @param {string} data.delivery_time 配送予定時間
 */
export const changeOrderProduct = (token, data) => {
  return (dispatch) => {
    dispatch(changeOrderProductRequest());
    return axios
      .put("/admin/order/product/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(changeOrderProductSuccess(res.data));
      })
      .catch((err) => dispatch(changeOrderProductFailure(err)));
  };
};

// ==================== 注文商品発送情報変更 ====================
const changeOrderShipmentRequest = () => {
  return {
    type: types.CHANGE_ORDER_SHIPMENT_REQUEST,
  };
};

const changeOrderShipmentSuccess = (data) => {
  return {
    type: types.CHANGE_ORDER_SHIPMENT_SUCCESS,
    data,
  };
};

const changeOrderShipmentFailure = (error) => {
  return {
    type: types.CHANGE_ORDER_SHIPMENT_FAILURE,
    error,
  };
};

/**
 * 注文した商品発送情報を更新する関数
 * @param {string} token トークン
 * @param {Object} data
 * @param {string} data.product_id 商品ID
 * @param {string} data.scheduled_delivery_date 配送予定日
 * @param {string} data.tracking_code 追跡番号
 * @param {string} data.couriers 配送会社 [SAGAWA or YAMATO]
 */
export const changeOrderShipment = (token, data) => {
  return (dispatch) => {
    dispatch(changeOrderShipmentRequest());
    return axios
      .put("/admin/order/product/shipment/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(changeOrderShipmentSuccess(res.data));
      })
      .catch((err) => dispatch(changeOrderShipmentFailure(err)));
  };
};

export const setFilter = (data) => {
  return {
    type: types.SET_ORDER_FILTER,
    data: data,
  };
};

export const clearFilter = () => {
  return {
    type: types.CLEAR_ORDER_FILTER,
  };
};

// ==================== 注文完了メール再送 ====================
const sendOrderMailRequest = () => {
  return {
    type: types.SEND_ORDER_MAIL_REQUEST,
  };
};

const sendOrderMailSuccess = () => {
  return {
    type: types.SEND_ORDER_MAIL_SUCCESS,
  };
};

const sendOrderMailFailure = (error) => {
  return {
    type: types.SEND_ORDER_MAIL_FAILURE,
    error,
  };
};

/**
 * 注文完了メールを再送する関数
 * @param {string} token トークン
 * @param {string} orderNumber 注文番号
 */
export const sendOrderMail = (token, orderNumber) => {
  return (dispatch) => {
    dispatch(sendOrderMailRequest());
    return axios
      .post(
        "/admin/order/mail/payment/",
        { order_number: orderNumber },
        {
          headers: { token: token },
        }
      )
      .then((res) => dispatch(sendOrderMailSuccess(res.data)))
      .catch((err) => dispatch(sendOrderMailFailure(err)));
  };
};

export const clearStatus = () => {
  return {
    type: types.CLEAR_ORDER_STATUS,
  };
};

export const clearError = () => {
  return {
    type: types.CLEAR_ORDER_ERROR,
  };
};

export const changePage = (page) => {
  return {
    type: types.CHANGE_ORDER_PAGE,
    page: page,
  };
};

// ==================== 商品ごとの返金 ====================
const refundProductRequest = () => {
  return {
    type: types.REFUND_PRODUCT_INFO_REQUEST,
  };
};

const refundProductSuccess = (data) => {
  return {
    type: types.REFUND_PRODUCT_INFO_SUCCESS,
    data,
  };
};

const refundProductFailure = (error) => {
  return {
    type: types.REFUND_PRODUCT_INFO_FAILURE,
    error,
  };
};

/**
 * 商品ごとの返金
 * @param {string} token トークン
 * @param {Object} data
 * @param {string} orderNumber 注文番号
 * @param {string} product_pk_list 商品IDリスト
 */
export const refundProduct = (token, data) => {
  return (dispatch) => {
    dispatch(refundProductRequest());
    return axios
      .post("/admin/order/product/refund/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(refundProductSuccess(res.data));
      })
      .catch((err) => dispatch(refundProductFailure(err)));
  };
};

// ==================== 商品の一部キャンセル ====================
const cancelProductRequest = () => {
  return {
    type: types.CANCEL_PRODUCT_REQUEST,
  };
};

const cancelProductSuccess = (data) => {
  return {
    type: types.CANCEL_PRODUCT_SUCCESS,
    data,
  };
};

const cancelProductFailure = (error) => {
  return {
    type: types.CANCEL_PRODUCT_FAILURE,
    error,
  };
};

/**
 * 商品の一部をキャンセルする関数
 * @param {string} token トークン
 * @param {Object} data
 * @param {string} orderNumber 注文番号
 * @param {string} product_pk_list 商品IDリスト
 */
export const cancelProduct = (token, data) => {
  return (dispatch) => {
    dispatch(cancelProductRequest());
    return axios
      .post("/admin/order/product/cancel/", data, {
        headers: { token: token },
      })
      .then((res) => {
        dispatch(cancelProductSuccess(res.data));
      })
      .catch((err) => dispatch(cancelProductFailure(err)));
  };
};
